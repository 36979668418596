import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Notes from '../templates/notes';
export const frontmatter = {
  title: 'Notes',
  description: 'This is my bag of tricks — loose notes, design patterns, rules-of-thumb, tools, cheatsheets, gimmicks, leverage points, descriptions of systems, key questions, risks, and unknowns.'
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Notes data={frontmatter} mdxType="Notes" />

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      